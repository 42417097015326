@import '../../variables';

.albumNode {
  width: 100%;
  height: 100%;
  overflow: hidden;
  transition: transform 0.5s ease-in-out, box-shadow 0.3s ease-in-out, background-color 0.3s ease-in-out; /* Apply transitions to transform, box-shadow, and background-color properties */

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    max-width: 100%;
    max-height: 100%;
  }

  &:hover {
    transform: scale(1.02); /* Scale up on hover */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Add a subtle shadow on hover */
    background-color: rgba(0, 0, 0, 0.05); /* Add a semi-transparent background color on hover */
    transition: transform 0.1s ease-in, box-shadow 0.1s ease-in-out, background-color 0.3s ease-in-out; /* Adjust transitions for hover state */
  }

  &:active {
    transform: scale(1.05); /* Scale up more on click */
    box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.1); /* Add a stronger shadow on click */
    background-color: rgba(0, 0, 0, 0.3); /* Add a slightly darker background color on click */
    transition: transform 0.1s ease-in, box-shadow 0.1s ease-in-out, background-color 0.3s ease-in-out 0.1s; /* Adjust transitions for click state with a delay */
  }
}
