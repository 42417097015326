@import '../variables';

.hero {
  background-color: $echo-foreground;
  border: 1px solid darken($echo-foreground, 10);
  padding: 3rem;
  //width: 40%;
  margin: 0 auto;
  color: black;
  border-radius: 0.5rem;
}

.homepageContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-items: center;
  justify-content: center;
  align-content: center;
  height: 100vh;
  max-height: 100vh;
  overflow: hidden;
}