@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.trackGrid {
  display: grid;
  width: 100%;
  // justify-content: space-between;
  // grid-gap: 10px; /* Optionally add gap between grid items */

  grid-template-columns: repeat(2, 1fr); // Default 4x4 layout

  @include media-breakpoint-up(xs) {
    grid-template-columns: repeat(2, 1fr);

    /* Default for 3 columns */
    //> div {
    //  grid-column: span 3;
    //}

    ///* For the second row pattern, the first item should span only 2 columns and the second one 4 columns */
    //> div:nth-child(3n+2) {
    //  grid-column: 2 / span 2; /* Starts from the second column and spans 2 columns */
    //}
    //> div:nth-child(3n+3) {
    //  grid-column: 4 / span 3; /* Starts from the fourth column and spans 3 columns */
    //}
  }


  @include media-breakpoint-up(sm) {
    grid-template-columns: repeat(4, 1fr);
  }

  @include media-breakpoint-up(md) {
    grid-template-columns: repeat(4, 1fr);
  }

  @include media-breakpoint-up(lg) {
    grid-template-columns: repeat(5, 1fr);
  }

  @include media-breakpoint-up(xl) {
    grid-template-columns: repeat(5, 1fr);
  }
}

.smaller {
  grid-template-columns: repeat(4, 1fr) !important;
}


.trackGrid.smallest {
  grid-template-columns: repeat(2, 1fr) !important;
}

.trackGrid.firstBigger {
  .albumNode:first-child {
    grid-row: span 2; // Make the first item span 2 rows
    grid-column: span 2; // Make the first item span 2 columns
  }
}

.trackGridHome {
  position:absolute;

  top: 0;
  left:0;
  z-index: -999;
  background-color: rgba(0, 0, 0, 0.5);  /* 50% opacity */
  opacity: .5;
  //background-blend-mode: color-burn;
  overflow-y: hidden;
  max-height: 100vh;
}

.hp {
  grid-template-columns: repeat(4, 1fr) !important;
}
