@import '../../variables';

.bottomNavigation {
  background-color: darken($echo-player, 2);
  height: 51px;
  width: 100%;
  border-top: 1px solid darken($echo-player, 5);

  display: grid;
  grid-template-columns: repeat(3, 1fr);
  //justify-content: space-around;
  align-items: center;
  text-transform: uppercase;
  padding: 0;
  margin: 0;
}

.item {
  display: flex;
  flex-direction: column;
  text-align: center;
  color: grey;

  i {
    font-size: 1.5rem;
  }
}

.active {
  color: #FFF;
}
