@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';
@import '../../variables';

.container {
  margin: 0.5rem 0.5rem;
  display: flex;
}

.player {
  margin-top: auto;
  width: 100%;
  background-color: $echo-player;
  border: 1px solid darken($echo-player, 3);
  border-radius: 0.5rem;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05); /* Add a subtle shadow on hover */
  //box-shadow: 0 2px 5px rgba(0, 0, 0, 0.07), 0 1px 2px rgba(0, 0, 0, 0.05);
  //box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05), 0 1px 2px rgba(0, 0, 0, 0.03), inset 0 0px 0px rgba(0, 0, 0, 0.01);


  opacity: 1;
  padding: 0.30rem 0.65rem;
  text-align: left;
  grid-template-columns: 1fr;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;


  // Change color of player text (songs and stuff are links)
  color: invert($echo-player, 100);
  a {
    color: invert($echo-player, 100);
  }

  svg {
    fill: $echo-player-secondary;
    color: $echo-player-secondary;
  }

  @include media-breakpoint-up(md) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }


  .playerLeft {
    display: flex;
    align-items: center;
    align-content: center;
    span {
      margin: 0;
      padding: 0;
    }
    span, p, a {
      font-size: 0.65rem;

      @include media-breakpoint-up(md) {
        font-size: 0.8rem;
      }
    }

    img {
      $player-image-size: 48px;
      height: $player-image-size;
      width: $player-image-size;
      @include media-breakpoint-up(md) {
        $player-image-size: 64px;
        height: $player-image-size;
        width: $player-image-size;
      }

      margin-right: 0.5rem;
    }

    .song {
      font-weight: bold;
      margin-bottom: 0;
      padding-bottom: 0;
      font-size: .75rem;
      @include media-breakpoint-up(md) {
        font-size: 1.0rem;
      }
    }

    .artist {
      font-weight: bold;
    }

    .album {
    }


  }

  .playerCenter {
    align-content: center;
    align-self: center;
    justify-content: center;
    justify-items: center;
    text-align: center;
  }

  .playerRight {
    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  input.form-range::-webkit-slider-thumb {
    background: lighten($echo-player, 35);
    border: 1px solid darken($echo-player, 30);
    height: 16px;
    width: 16px
  }

  input.form-range::-webkit-slider-runnable-track {
    background: darken($echo-player, 5);
    border: 1px solid darken($echo-player, 25);
    height: 10px;
  }


  input.form-range::-moz-range-thumb {
    background: $echo-player;
    border: 1px solid $echo-primary;
  }

  input.form-range::-ms-thumb {
    background: $echo-player;
    border: 1px solid $echo-primary;
  }

}


.controls {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  a {
    margin-right: 0.5rem;
  }
  svg:hover {
    transform: scale(1.05);
  }
}

.spotifyButton{
  margin-left: auto; // push all the way to the right
  background-color: #1db954;
  border: none;
  color: #FFF;
  padding: 0.25rem;
  border-radius: 0.25rem;
  font-size: 0.75rem;
  font-weight: bold;
}
