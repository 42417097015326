@import '../../variables';

.albumContainer {
  border: 1px solid $echo-secondary;
  padding: 0;
  display: flex;
  flex-direction: column;
  perspective: 1500px;
}

.exploreItemHeader {
  display: flex;
  background-color: lighten($echo-background, 15);
  border-bottom: 1px solid darken($echo-background, 50);
  padding: 0.7rem 0.7rem;
  justify-content: space-between;
  a {
    color: $echo-foreground;
  }
  a:hover {
    color: $echo-accent;
  }
}

.cardContent {
  position: relative;
  transition: transform 0.2s; // Added transition
  transform-style: preserve-3d;
  height: 87vw;

  .front, .back {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    //backface-visibility: hidden; // Hide the back of the component when it is rotated
  }

  .front {
    //background-color: red;
    transform: rotateY(0deg);
  }

  .back {
    //background-color: blue;
    transform: rotateY(180deg);
    background-color: lighten($echo-primary, 20);
    height: 100%;
    overflow-y: scroll;
    scroll-behavior: smooth;
    color: white;

    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 0.25rem;

    ::-webkit-scrollbar {
      -webkit-appearance: scrollbarthumb-vertical;
      width: 10px;
    }

  }
}

.showFront .front {
  z-index: 2;
  opacity: 1;
}

.showFront .back {
  z-index: 1;
  opacity: 0;
}

.showBack {
  transform: rotateY(180deg);
}

.showBack .front {
  z-index: 1;
  opacity: 0;
}

.showBack .back {
  z-index: 2;
  opacity: 1;
}


.trackInline {
  display: flex;
  flex-direction: row;
  border-top: 1px solid lighten($echo-primary, 15);
  margin: 0.05rem 0;

  img {
    height: 72px;
    width: 72px;
  }

  p {
    margin: 0;
  }
}
