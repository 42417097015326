$echo-primary: #000000;
$echo-secondary: rgba(0, 0, 0, 0.1); // Lighter color for reflection

//$echo-player: rgba(244, 244, 244, 1);
$echo-player: #191414;
//$echo-light

$echo-header: #f8f9fa;

$echo-player-secondary: darken($echo-header, 1);

$echo-blue: darken(lightblue, 10);

$echo-pink: #fae4e5;
$echo-red: #e75042;
$echo-darkblue: #3f79ca;

$echo-background: #252424;
$echo-foreground: white;
$echo-accent: $echo-red;
