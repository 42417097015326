@import './variables';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  //overflow-x:initial;
  //background-color: #f5f5f5;
  background-color: $echo-background;
  color: $echo-foreground;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

a {
  text-decoration: none;
}



// Bootstrap fix sticky footer being pushed down by dropdowns
nav ul li {
  position: relative;
}

nav ul ul {
  position: absolute;
  width: 100%;
}