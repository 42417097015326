
.loadingContainer {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 10rem;

}

.center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.slide {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  svg {
    margin: 1rem 0;
  }
  h2 {
    font-weight: bold;
  }

}

.slide {
  transition: opacity 1s ease-in-out;

  &.fadeIn {
    opacity: 1;
  }

  &.fadeOut {
    opacity: 0;
  }
}